@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
}

.header {
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
}

.margin-min {
  margin-top: -10%;
}

@media screen and (max-width: 768px) {
  .margin-min {
    margin-top: -22%;
  }
}

.bg-image {
  background-image: url('./assets/images/bg-compilation.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.slick-prev {
  left: 15% !important;
  width: 200px;
  z-index: 1 !important;
}

.slick-next {
  right: 15% !important;
  z-index: 1 !important;
}

.filtered {
  filter: grayscale(50%) contrast(70%) brightness(70%);
}
